<template>
  <main>
    <section id="annual_list">
      <div class="w1300 annual_container">

        <div class="main_title_box">
          <h3 class="main_title txt-bold">年會報名</h3>
          <div class="img_box">
            <img src="@/statics/img/index/icon_title2.svg" alt="">
          </div>
        </div>

        <div class="content">
          <!-- <ul class="event_list">
            <li v-for="event in annualList" :key="event.id">
              <router-link
                :to="`/register/annual/info?event_uuid=${event.uuid}`"
                class="cards_item"
              >
                <div class="wrap top">
                  <div class="col100">
                    <div class="card_title_wrapper">
                      <p class="card_title">{{ result.name }}</p>
                      <span class="episode"><small>{{ result.video_count }}集</small></span>
                    </div>
                  </div>
                  <div class="col100 bottom">
                    <div class="col70 course_info course_price">
                      <div class="fraction_box">
                        <p
                          class="small type-A"
                          v-show="result.fraction_a && result.fraction_a * 1 !== 0"
                        >
                          <span class="tag">A類</span>
                          <span class="credit">．{{ result.fraction_a }}學分</span>
                        </p>
                        <p
                          class="small type-B"
                          v-show="result.fraction_b && result.fraction_b * 1 !== 0"
                        >
                          <span class="tag">B類</span>
                          <span class="credit">．{{ result.fraction_b }}學分</span>
                        </p>
                      </div>
                      <p class="time ls1 small">課程時長: {{ result.length }}</p>
                      <p class="period ls1 small">
                        觀看期限: 付款成功後 {{ result.expiry_time }} 天內
                      </p>
                    </div>
                    <div class="col30">
                      <button
                      type="button"
                      class="addItem-btn"
                      :class="{ 'deleteItem-btn': isActive(result.uuid) }"
                      @click.prevent="handleCartItem(result.uuid)"
                      >
                        <small v-show="!isActive(result.uuid)">+加入清單</small>
                        <small v-show="isActive(result.uuid)">－移除課程</small>
                      </button>
                    </div>
                  </div>
                </div>
              </router-link>
            </li>
          </ul> -->
          <ul class="event_list">
            <li v-for="event in annualList" :key="event.id">
              <router-link
                :to="`/register/annual/info?event_id=${event.uuid}`"
                class="cards_item"
              >
                <div class="wrap">
                  <div class="col20">
                    <div class="data_wrap">
                      <h4 class="ym">
                        {{ eventYear(event.start_date) }} . {{ eventMonth(event.start_date) }}
                      </h4>
                      <h1 class="d">{{ eventDay(event.start_date) }}</h1>
                    </div>
                  </div>
                  <div class="col80">
                    <p class="card_title">{{ event.name }}</p>
                    <!-- <p class="small" :class="`type-${event.tag}`">
                      <span class="tag">{{ event.tag }}類</span>
                      <span class="credit">．{{ event.credit }}學分</span>
                    </p> -->
                    <div class="fraction_box">
                      <p
                        class="small type-A"
                        v-show="event.fraction_a && event.fraction_a * 1 !== 0"
                      >
                        <span class="tag">A類</span>
                        <span class="credit">．{{ event.fraction_a }}學分</span>
                      </p>
                      <p
                        class="small type-B"
                        v-show="event.fraction_b && event.fraction_b * 1 !== 0"
                      >
                        <span class="tag">B類</span>
                        <span class="credit">．{{ event.fraction_b }}學分</span>
                      </p>
                    </div>
                    <p class="location ls1">{{ event.place }}</p>
                    <p class="time ls1">
                      {{ eventTime(event.start_date) }} ~ {{ eventTime(event.end_date) }}
                    </p>
                  </div>
                </div>
              </router-link>
            </li>
          </ul>
        </div>

      </div>
    </section>
  </main>
</template>

<script>
import { annualIndex } from '@/lib/http';
import _ from 'lodash';
import moment from 'moment';

export default {
  name: 'RegisterAnnualIndex',
  data() {
    return {
      annualList: [],
      eventList: [
        {
          id: 1,
          route: '/register/annual/info',
          date: {
            year: '2020',
            month: '08',
            day: '21',
          },
          title: '台灣居家醫療醫學會2020年學術研討會暨第二屆年會',
          tag: 'A',
          credit: '25',
          location: '東海大學-外文館 (請由東大路進入)',
          time: '10:00 - 16:00 (6 hours)',
        },
        {
          id: 2,
          route: '/register/annual/info',
          date: {
            year: '2020',
            month: '08',
            day: '21',
          },
          title: '台灣居家醫療醫學會2020年學術研討會暨第二屆年會',
          tag: 'B',
          credit: '25',
          location: '東海大學-外文館 (請由東大路進入)',
          time: '10:00 - 16:00 (6 hours)',
        },
      ],
    };
  },
  created() {
    annualIndex().then((result) => {
      this.annualList = _.get(result, 'data.result.data', []);
    });
  },
  methods: {
    eventYear(date) {
      return moment(date).format('YYYY') || '';
    },
    eventMonth(date) {
      return moment(date).format('MM') || '';
    },
    eventDay(date) {
      return moment(date).format('DD') || '';
    },
    eventTime(date) {
      return moment(date).format('YYYY-MM-DD kk:mm');
    },
  },
};
</script>
